<template>
  <div v-if="tab == 1" class="application realTime-details">
    <div v-if="!show && !currentLoan" class="no-result">No data available</div>
    <div v-else class="table">
      <div class="item">
        <div>Payment Date</div>
        <div>
          {{ moment(currentLoan.created_at).format("MMM Do YYYY") || "-" }}
        </div>
      </div>
      <div class="item">
        <div>Full Premium</div>
        <div>
          {{
            (currentLoan.loan_amount || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
      </div>
      <div class="item">
        <div>Daily Premium</div>
        <div>
          {{
            (currentLoan.daily_premium || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
      </div>
      <div class="item">
        <div>Equity Contribution</div>
        <div>
          {{
            (
              currentLoan.total_upfront_payment - currentLoan.total_fee || 0
            ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
      </div>
      <div class="item">
        <div>Management Fee</div>
        <div>
          {{
            (
              currentLoan?.total_fee -
                currentLoan.gateway_fee -
                currentLoan.credit_check_fee || 0
            ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
      </div>
      <div class="item">
        <div>Utilized Days</div>
        <div>{{ currentLoan.utilized_days }}</div>
      </div>
      <div class="item">
        <div>Earned Premium</div>
        <div>
          {{
            (currentLoan.earned_premium || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
      </div>
      <div class="item">
        <div>Unearned Premium</div>
        <div>
          {{
            (currentLoan.unearned_premium || 0).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })
          }}
        </div>
      </div>
      <div class="item">
        <div>Daily Loan Repayment Amount</div>
        <div>
          {{
            (currentLoan.daily_loan_repayment_amount || 0).toLocaleString(
              "en-NG",
              {
                style: "currency",
                currency: "NGN",
              }
            )
          }}
        </div>
      </div>
      <div class="item">
        <div>Outstanding Loan Balance</div>
        <div>
          {{
            (currentLoan.outstanding_loan_balance || 0).toLocaleString(
              "en-NG",
              {
                style: "currency",
                currency: "NGN",
              }
            )
          }}
        </div>
      </div>
      <div class="item">
        <div>Coverage Ratio</div>
        <div>{{ currentLoan.coverage_ratio || "0" }}%</div>
      </div>
    </div>
  </div>
  <div v-if="tab == 2" class="application loanFacilty-details">
    <div class="table">
      <div class="table-head">Payment Date</div>
      <div class="table-head">Days Covered</div>
      <div class="table-head">Repayment (No. of Days)</div>
      <div class="table-head">Premium Earned</div>
      <div class="table-head">Unearned Premium</div>
      <div class="table-head">Monthly Payment</div>
      <div class="table-head">
        Outstanding Loan Balance (Before Monthly Payment)
      </div>
      <div class="table-head">Outstanding Loan Balance</div>
      <div class="table-head">Coverage Ratio</div>
      <div class="table-head">Payment Status</div>
      <div class="table-head">Amount Paid</div>
      <div class="table-head">Paystack Charges</div>
      <div class="table-head">Net Amount</div>
      <div class="table-head">Actual Payment Date</div>
    </div>
    <div v-if="!show && !currentLoan" class="no-result">No data available</div>

    <div
      v-else
      v-for="(schedule, index) in currentLoan.schedule"
      :key="index"
      class="table"
    >
      <div class="table-data">
        {{ moment(schedule.due_date).format("MMM Do YYYY") || "-" }}
      </div>
      <div class="table-data">{{ schedule.days_covered }}</div>
      <div class="table-data">{{ schedule.repayment_days }}</div>
      <div class="table-data">
        {{
          (schedule.earned_premium || 0).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div class="table-data">
        {{
          (schedule.unearned_premium || 0).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div class="table-data">
        {{
          (
            schedule.total_monthly_payment - Math.abs(schedule.gateway_fee) || 0
          ).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div class="table-data">
        {{
          (schedule.outstanding_loan_balance_before || 0).toLocaleString(
            "en-NG",
            {
              style: "currency",
              currency: "NGN",
            }
          )
        }}
      </div>
      <div class="table-data">
        {{
          (schedule.outstanding_loan_balance || 0).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div class="table-data">{{ schedule.coverage_ratio || "-" }}</div>
      <div class="table-data">
        <div v-if="schedule.status === 'past_due'" class="status past">
          Past Due
        </div>
        <div
          v-else
          :class="[
            'status',
            schedule.status === 'paid' ? 'approved' : 'pending',
          ]"
        >
          {{ schedule.status }}
        </div>
      </div>
      <div class="table-data">
        {{
          (schedule.monthly_constant_payment || 0).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div class="table-data">
        {{
          (schedule.gateway_fee || 0).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div class="table-data">
        {{
          (schedule.net_amount || 0).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div v-if="schedule.payment_date" class="table-data">
        {{ moment(schedule.payment_date).format("MMM Do YYYY") }}
      </div>
      <div v-else class="table-data">-</div>
    </div>
  </div>
  <div v-if="tab == 3" class="application transaction-history">
    <div class="table">
      <div class="table-head">Flow</div>
      <div class="table-head">Activities</div>
      <div class="table-head">Amount</div>
      <div class="table-head">Category</div>
      <div class="table-head">Reference</div>
      <div class="table-head">Date</div>
      <div class="table-head"></div>
    </div>
    <div v-if="!show && transactions.length === 0" class="no-result">
      No data available
    </div>

    <div
      v-else
      v-for="(transaction, index) in transactions"
      :key="index"
      class="table"
    >
      <div v-if="transaction.type == 'debit'" class="table-data">
        <arrow-up-right-icon color="red" size="17" />
      </div>
      <div v-else-if="transaction.type == 'credit'" class="table-data">
        <arrow-down-left-icon color="green" size="17" />
      </div>
      <div v-else class="table-data">-</div>
      <div class="table-data">{{ transaction.meta }}</div>
      <div class="table-data">
        {{
          transaction.amount?.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div class="table-data">{{ transaction.description }}</div>
      <div class="table-data">{{ transaction.reference }}</div>
      <div class="table-data">
        {{ moment(transaction.created_at).format("MMMM Do YYYY, h:mm:ss a") }}
      </div>
      <div class="table-data">
        <div
          v-if="transaction.status !== 'success'"
          @click.stop="doRetry(transaction)"
          class="retry"
        >
          <span>Retry</span>
        </div>
      </div>
    </div>
  </div>
  <div v-if="tab == 4" class="application terminated-loans">
    <div class="table">
      <div class="table-head">Amount Loaned</div>
      <div class="table-head">Amount Paid</div>
      <div class="table-head">Default</div>
      <div class="table-head">Refunds</div>
    </div>
    <div v-if="!show && cancelledLoans.length === 0" class="no-result">
      No data available
    </div>

    <div
      v-else
      v-for="(loan, index) of cancelledLoans"
      :key="index"
      class="table"
    >
      <div class="table-data">
        {{
          loan.loan_amount?.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div class="table-data">
        {{
          loan.total_payment?.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        }}
      </div>
      <div class="table-head">
        {{
          loan.outstanding_loan_balance?.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          }) || "-"
        }}
      </div>
      <div class="table-head">
        {{
          loan.refunded_amount?.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          }) || "-"
        }}
      </div>
    </div>
  </div>
  <div v-if="tab == 5" class="application credit-reports">
    <div class="table">
      <div class="table-head">Provider</div>
      <div class="table-head">Report</div>
    </div>
    <div v-if="!show && !currentLoan" class="no-result">No data available</div>

    <div
      v-else
      v-for="(report, index) in currentLoan.credit_reports"
      :key="index"
      class="table"
    >
      <div class="table-data">
        <span>{{ report.provider }}</span>
      </div>
      <div class="table-data">
        <a
          :download="`${currentLoan?.user?.first_name}_${currentLoan?.user?.last_name}_credit_report`"
          :href="`data:application/pdf;base64,${report.pdf_content}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <download-icon color="#B6B9BE" />
        </a>
      </div>
    </div>
  </div>
  <div v-if="tab == 6" class="application loanFacilty-details">
    <div class="button-container">
      <span>{{ cardUrl }} </span>
      <button @click="handleInitializeCard" class="export">
        <Spinner v-if="loading" />
        <span v-else>Add Card</span>
      </button>
    </div>
    <div class="table">
      <div class="table-head">Card Name</div>
      <div class="table-head">Card Type</div>
      <div class="table-head">Card's Bank</div>
      <div class="table-head">Card Expiry Date</div>
      <div class="table-head">Card's Status</div>
    </div>
    <div v-if="!show && !cards.length" class="no-result">No data available</div>

    <div v-else v-for="(card, index) in cards" :key="index" class="table">
      <div class="table-data">{{ card.name || "-" }}</div>
      <div class="table-data">{{ card.card_type || "-" }}</div>
      <div class="table-data">{{ card.bank || "-" }}</div>
      <div class="table-data">
        {{ `${card.card_exp_month}-${card.card_exp_year}` || "-" }}
      </div>

      <div class="table-data">
        <div v-if="isCardExpired(card)" class="status past">Expired</div>
        <div v-else class="status approved">Active</div>
      </div>
    </div>
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";
export default {
  data() {
    return {
      cardUrl: null,
      loading: false,
      show: false,
    };
  },
  props: {
    tab: {
      type: Number,
      required: true,
    },
    transactions: {
      type: Array,
      required: true,
    },
    loans: {
      type: Array,
      required: true,
    },
    paidLoans: {
      type: Array,
      required: true,
    },
    cancelledLoans: {
      type: Array,
      required: true,
    },
    creditReport: {
      type: Array,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
    currentLoan: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    ...mapActions({
      initializeCard: "customer/initializeCard",
      retryLoanDebit: "loan/retryDebit",
    }),

    isCardExpired(card) {
      const currentDate = new Date();
      const cardExpirationDate = new Date(
        card.card_exp_year,
        card.card_exp_month - 1,
        1
      );

      return cardExpirationDate < currentDate;
    },

    async doRetry(transaction) {
      this.show = true;


    try {
         await this.retryLoanDebit({ loan_id: transaction.loan_id });
      } catch (e) {}

      this.show = false;
      this.getTransactions();
    },

    async handleInitializeCard() {
      this.loading = true;
      try {
        const response = await this.initializeCard({
          userId: this.currentLoan.user.id,
        });
        if (response && response?.data?.authorization_url) {
          this.cardUrl = response?.data?.authorization_url;
          this.toast.success("Card initialized successfully");
        }
      } catch (error) {
        this.toast.error(
          error?.response?.message ||
            error.message ||
            "Something went wrong. Try again later."
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.application {
  margin: 0 0 50px;
}

.realTime-details {
  .table {
    display: grid;
    font-size: 15px;
    color: #49505d;

    .item {
      border: 1px solid;
      text-align: right;
      display: flex;
      width: 900px;
      padding: 15px;
      justify-content: space-between;
    }
  }
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: end;

  & .export {
    font-size: 12px;
    background: #252d3d;
    background-color: rgb(37, 45, 61);
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-self: center;
    cursor: pointer;
    width: 90px;
    display: flex;
    border-radius: 3px;
    border: none;

    &:disabled {
      background: #777;
      cursor: not-allowed;
    }
  }
}
</style>
